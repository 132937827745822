












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TitleOptionQuestion extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
}
