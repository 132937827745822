









































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';
const DISABLED_FILL = 'var(--neutralcolor-low-light)';

@Component
export default class Calendar extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: false }) isDisabled!: boolean;

  private DISABLED_FILL = DISABLED_FILL;
}
