











import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class OptionQuestion extends Vue {
  @Prop() title!: string;
  @Prop() name!: string;
  @Prop() mainIndex!: number;
  @Prop() mutiple!: boolean;
  // @Prop() choice!: Function;
  @Prop() id!: number;
  @Prop() isActive!: boolean;

  setChoiceOption() {
    this.$emit('choice-option', this.mutiple, this.id, this.mainIndex);
    // this.choice(this.id, this.mainIndex);
  }
}
