import { render, staticRenderFns } from "./WrapperQuestion.vue?vue&type=template&id=17e7c5c9&scoped=true&"
import script from "./WrapperQuestion.vue?vue&type=script&lang=ts&"
export * from "./WrapperQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./WrapperQuestion.scss?vue&type=style&index=0&id=17e7c5c9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e7c5c9",
  null
  
)

export default component.exports